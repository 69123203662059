.cdwsection {
    display: flex;
    padding: 42px;
    align-items: flex-start;
    gap: -50px;
    align-self: stretch;
    border-radius: 40px;
    margin-left: 12px;
    margin-right: 12px;

}



.cdwsectionbdark {
    background: linear-gradient(246deg, #2D2925 0%, #161412 100%);
}

.cdwsectionblue {
    background: var(--Grad-Azul, linear-gradient(246deg, #1E4F6C 0%, #0E1C34 100%));

}

.leadbutton {
    background: var(--Grad-Laranja, linear-gradient(256deg, #ff9d00 3.03%, #FE4D1B 57.29%, #E70154 115.7%));
}

.heroImg {
    max-width: 477px;
}

.iplodevcontainer {
    width: 100%;
}

@media (min-width: 640px) {
    .iplodevcontainer {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .iplodevcontainer {
        max-width: 768px;
    }

    .max-h-uiux {
        max-height: 480px;
    }
}

@media (min-width: 1024px) {
    .iplodevcontainer {
        max-width: 1024px;
    }

    .cdwsection {
        padding: 64px
    }


}

@media (min-width: 1280px) {
    .iplodevcontainer {
        max-width: 1280px;
    }

    .max-h-uiux {
        max-height: 420px;
    }
}